<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      >
        <template
          slot="cardActionsLeft"
        >
          <v-btn
            v-if="formattedItem.checklist_ot && formattedItem.checklist_ot.idchecklist_ot"
            icon
            color="primary"
            @click.stop="clickIrAChecklist"
          >
            <v-icon>{{ $vuetify.icons.values.checklist }}</v-icon>
          </v-btn>
        </template>
      </b10-view-summary>
      <div
        class="px-4"
      >
        <total-intervencion
          :mostrar-importe="mostrarImporte"
          :totales="totalesParteTrabajo"
          :total-cobrado="totalCobrado"
          :has-perm-cobrar-intervencion="hasPerm(permissions.partes.cobrarIntervencion)"
          @click-desglose="clickDesgloseValoracion"
          @click-cobrar="clickCobrar"
        />
      </div>
      <div
        v-if="formattedItem.checklist_ot && formattedItem.checklist_ot.idchecklist_ot"
        class="px-4 mt-2"
      >
        <total-checklist
          :title="formattedItem.checklist_ot.descripcion"
          :anomalias="formattedItem.anomalias"
          :count-acciones-correctoras="formattedItem.accionesCorrectoras.count"
          :count-preguntas-desconocidas="formattedItem.countPreguntasDesconocidas"
          :resultado-checklist="formattedItem.resultadoChecklist"
          :porcentaje-progreso="formattedItem.porcentajeProgreso"
          @click-anomalias="clickAnomalias"
          @click-acciones-correctoras="clickAccionesCorrectoras"
        />
      </div>
      <div
        class="px-4 mt-2"
      >
        <total-tiempo-trabajado
          :totales="totalTiempoTrabajado"
          @click-desglose="clickDesgloseTiempoTrabajado"
        />
      </div>
      <total-operaciones
        v-if="networkOnline && countOperacionCrm > 0"
        :totales="countOperacionCrm"
        @click-operaciones="clickIrAOperaciones"
      />
      <b10-file-gallery
        class="pa-3"
        :images="ficherosGaleria"
        data-field="data"
        type-field="type"
        filename-field="nombre_fichero"
        :can-download="false"
      />
    </b10-page-content>
    <desglose-intervencion-dialog
      v-if="formattedItem && formattedItem.regimen_fiscal"
      v-show="lineasParteTrabajo.length > 0"
      :showing.sync="showingDialogs.desgloseIntervencion"
      :lineas="lineasParteTrabajo"
      :mostrar-importe="mostrarImporte"
      :con-i-v-a="formattedItem.regimen_fiscal.con_iva"
      :con-recargo="formattedItem.regimen_fiscal.con_recargo"
    />
    <desglose-tiempo-trabajado-dialog
      v-if="totalTiempoTrabajado > 0"
      :showing.sync="showingDialogs.desgloseTiempoTrabajado"
      :mostrar-tiempos-desplazamiento="mostrarTiemposDesplazamiento"
      :lineas="tiempoTrabajado"
    />
    <b10-bottom-navigation
      :buttons="bottomNavBarButtons"
      @click-bottom-nav-bar-button="clickBottomNavBarButton"
    />
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './ParteResumenClienteViewData'
import { get } from 'vuex-pathify'
import { calcTotal } from '@/utils/total'
import { PARAMETRO, CHECKLIST, VALOR_MOSTRAR_VALORACION_INTERVENCION, TABLA } from '@/utils/consts'
import { nonEmpty } from '@/utils/templates'
import TotalIntervencion from './components/TotalIntervencion'
import TotalTiempoTrabajado from './components/TotalTiempoTrabajado'
import TotalChecklist from './components/TotalChecklist'
import TotalOperaciones from './components/TotalOperaciones'
import DesgloseIntervencionDialog from './components/DesgloseIntervencionDialog'
import DesgloseTiempoTrabajadoDialog from './components/DesgloseTiempoTrabajadoDialog'
import { doSync } from '@/sync/upload'
import colors from 'vuetify/lib/util/colors'

export default {
  components: {
    TotalIntervencion, TotalTiempoTrabajado, TotalChecklist, DesgloseIntervencionDialog, DesgloseTiempoTrabajadoDialog, TotalOperaciones
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: {
        parte: {
          title: 'Parte',
          rows: [
            { name: 'parte_trabajo.idparte_trabajo', label: 'Nº parte' },
            { name: 'parte_trabajo.fparte', label: 'Fecha', filter: this.$options.filters.shortDate },
            { name: 'orden_trabajo.orden_trabajo_serie_numero', label: 'Nº orden de trabajo' },
            { name: 'tactuacion.descripcion', label: 'Tipo de actuación' },
            { name: 'tcierre_parte.descripcion', label: 'Tipo de cierre' },
          ],
        },
        checklist: {
          title: 'Checklist',
          rows: [
            { name: 'checklist.observaciones', label: 'Observaciones' },
          ],
        },
        firmaParte: {
          title: 'Firma del parte',
          rows: [
            { name: 'parte_trabajo.ffirma', label: 'Fecha de firma', filter: this.$options.filters.shortDate },
            { name: 'parte_trabajo.firmante', label: 'Firmante' },
            { name: 'parte_trabajo.dni_firmante', label: 'DNI firmante' },
          ],
        },
        firmaChecklist: {
          title: 'Firma del checklist',
          rows: [
            { name: 'checklist_ot.ffirma', label: 'Fecha de firma', filter: this.$options.filters.shortDate },
            { name: 'checklist_ot.firmante', label: 'Firmante' },
            { name: 'checklist_ot.firmante_dni', label: 'DNI firmante' },
            { name: 'checklist_ot.firmante_cargo', label: 'Cargo firmante' },
          ],
        },
      },
      showingDialogs: {
        moreInfo: false,
        desgloseIntervencion: false,
        desgloseTiempoTrabajado: false,
      },
      mostrarImporte: false,
      totalesParteTrabajo: {},
      totalCobrado: 0,
      lineasParteTrabajo: [],
      ficherosGaleria: [],
      tiempoTrabajado: [],
      mostrarTiemposDesplazamiento: false,
      colors,
      navBarButtonsVariablesWithNetworkStatus: ['subir', 'addOperacion'],
      countOperacionCrm: 0,
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    networkOnline: get('network/online'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.parte_trabajo.idparte_trabajo
        item.title = `Parte de trabajo Nº${this.routeParams.idparte_trabajo}`
        item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.cliente.nombre} (${item.cliente.idcliente})
          ${item.tactuacion.descripcion} de ${item.tsistema.descripcion} de sistema ${item.sistema.descripcion} (${item.sistema.codigo})
          ${item.direccion}`
        )
        item.alerts = []
        if (item.emails.length === 0) {
          item.alerts.push({
            value: 'Cliente sin dirección de correo electrónico de servicio técnico',
            type: 'warning',
            options: { persistent: true, actions: [{ text: 'Añadir', flat: true, handler: this.clickIrContactos }], },
          })
        }
        if (this.estadoChecklistPendiente) {
          item.alerts.push({
            value: `El checklist se encuentra pendiente`,
            type: 'warning',
            options: { persistent: true, actions: [{ text: 'Finalizar', flat: true, handler: this.clickFinalizarChecklist }], },
          })
        }
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
    estadoChecklistPendiente () {
      return this.item.dataset.checklist_ot.estado === CHECKLIST.estados.pendiente
    },
    firmarVisible () {
      return (
        this.formattedItem && this.formattedItem.parte_trabajo && (
          (!this.formattedItem.parte_trabajo.firma) ||
          (this.formattedItem.checklist_ot.idchecklist_ot && !this.formattedItem.checklist_ot.firma && !this.estadoChecklistPendiente)
        )
      )
    },
    totalTiempoTrabajado () {
      return _.sumBy(this.tiempoTrabajado, (tiempo) => {
        if (
          !tiempo.tiempo_trabajado.es_desplazamiento ||
          tiempo.tiempo_trabajado.manodeobrafacturable ||
          this.mostrarTiemposDesplazamiento
        ) {
          return tiempo.tiempoTrabajado
        } else {
          return 0
        }
      })
    },
  },
  watch: {
    async networkOnline (newValue) {
      for (const variable of this.navBarButtonsVariablesWithNetworkStatus) {
        const index = _.findIndex(this.bottomNavBarButtons, {'name': variable})
        this.$set(this.bottomNavBarButtons[index], 'visible', newValue)
      }
      if (newValue) {
        await this.tieneOpracionCRM()
      }
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = `Resumen y firma parte Nº${this.routeParams.idparte_trabajo}`
      // total intervención
      const valorParametroMostrarIntervencion = await this.$offline.parametro.valorParametro(
        PARAMETRO.parametros.MOSTRAR_VALORACION_INTERVENCION
      )
      if (valorParametroMostrarIntervencion == VALOR_MOSTRAR_VALORACION_INTERVENCION.si) {
        this.mostrarImporte = true
      }  else if (valorParametroMostrarIntervencion == VALOR_MOSTRAR_VALORACION_INTERVENCION.forma_pago_movilidad) {
        const ordenTrabajo = await this.$offline.formaPago.FormaDePagoDeParte(this.routeParams.idparte_trabajo)
        this.mostrarImporte = ordenTrabajo.forma_pago.movilidad
      } else {
        this.mostrarImporte = false
      }
      this.totalesParteTrabajo = calcTotal(
        await this.$offline.parteTrabajo.selectParteTrabajoTotal(this.routeParams.idparte_trabajo)
      )
      this.totalCobrado = await this.$offline.ordenTrabajo.totalCobrado(
        this.item.dataset.parte_trabajo.idorden_trabajo
      )
      // tiempo trabajado
      this.tiempoTrabajado = await Data.selectTiempoTrabajado(this, this.routeParams.idparte_trabajo)
      this.mostrarTiemposDesplazamiento = (await this.$offline.parametro.valorParametro(
        PARAMETRO.parametros.MOSTRAR_TIEMPO_DESPLAZAMIENTO
      )) === '1'
      // galería con firma de parte, checklist y grado de satisfacción
      if (this.item.dataset.parte_trabajo.firma) {
        this.ficherosGaleria.push({
          data: this.item.dataset.parte_trabajo.firma,
          type: 'image/png',
          nombre_fichero: 'firma_parte_trabajo.png',
        })
      }
      if (this.item.dataset.checklist_ot.firma) {
        this.ficherosGaleria.push({
          data: this.item.dataset.checklist_ot.firma,
          type: 'image/png',
          nombre_fichero: 'firma_checklist.png',
        })
      }
      if (this.item.dataset.nivel_satisfaccion.icono) {
        this.ficherosGaleria.push({
          data: this.item.dataset.nivel_satisfaccion.icono,
          type: 'image/png',
          nombre_fichero: 'nivel_satisfaccion.png',
        })
      }
      this.loadNavigationBottomButtons()
      await this.tieneOpracionCRM ()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectParteTrabajo(this, this.routeParams.idparte_trabajo)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadNavigationBottomButtons () {
      this.initNavigationBottom()
      this.addNavigationBottomButton(
        'Subir', 'subir', 'upload', this.networkOnline
      )
      this.addNavigationBottomButton(
        'Operación', 'addOperacion', 'plus', this.networkOnline
      )
      this.addNavigationBottomButton(
        'Firmar', 'firmar', 'signature', this.firmarVisible
      )
    },
    async clickDesgloseValoracion () {
      if (!this.showingDialogs.desgloseIntervencion) {
        this.lineasParteTrabajo = await Data.selectLparteTrabajo(this, this.routeParams.idparte_trabajo)
      }
      this.showingDialogs.desgloseIntervencion = !this.showingDialogs.desgloseIntervencion
    },
    async clickDesgloseTiempoTrabajado () {
      this.showingDialogs.desgloseTiempoTrabajado = !this.showingDialogs.desgloseTiempoTrabajado
    },
    clickFirmar () {
      this.$appRouter.push({
        name: 'offline__parte-firmar',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
        },
      })
    },
    clickIrContactos () {
      this.$appRouter.push({
        name: 'offline__cliente-telefono-list',
        params: {
          idcliente: this.item.dataset.sistema.idcliente,
        },
        query: {
          idsistema: this.item.dataset.sistema.idsistema,
        },
      })
    },
    clickFinalizarChecklist () {
      this.$appRouter.push({
        name: 'offline__checklist-view',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: this.item.dataset.checklist_ot.idchecklist_ot,
        }
      })
    },
    clickAnomalias () {
      this.$appRouter.push({
        name: 'offline__anomalia-list',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: this.item.dataset.checklist_ot.idchecklist_ot,
        },
      })
    },
    clickAccionesCorrectoras () {
      this.$appRouter.push({
        name: 'offline__accion-correctora-list',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: this.item.dataset.checklist_ot.idchecklist_ot,
        },
      })
    },
    async clickSubir () {
      // antes de subir sincronizar para poder consultar lineas de OT pendientes de instalar
      await doSync(this.$offline, this.$api, this.$loading, this.$alert)
      this.$appRouter.push({
        name: 'partes__parte-subir',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
        },
      })
    },
    clickIrAChecklist () {
      this.$appRouter.push({
        name: 'offline__checklist-view',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: this.item.dataset.checklist_ot.idchecklist_ot,
        },
      })
    },
    clickCobrar () {
      this.$appRouter.push({
        name: 'offline__parte-cobrar',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
        },
      })
    },
    clickAddOperacion () {
      this.$appRouter.push({
        name: 'operacionescrm__parte-trabajo-operacioncrm-simple-add',
        params: {
          idorden_trabajo: this.item.dataset.parte_trabajo.idorden_trabajo,
        },
        query: {
          'descripcion': `En relación con el parte ${this.routeParams.idparte_trabajo}`
        }
      })
    },
    clickBottomNavBarButton (data) {
      switch (data.button.name) {
      case 'subir':
        this.clickSubir()
        break
      case 'addOperacion':
        this.clickAddOperacion()
        break
      case 'firmar':
        this.clickFirmar()
        break
      default:
        console.error(`La opción ${data.button.name} no está contemplada`)
      }
    },
    async tieneOpracionCRM () {
      if (this.networkOnline) {
        const resp = await this.$online.operacioncrm.selectDeIdentificadorYTabla(
          this.item.dataset.parte_trabajo.idorden_trabajo,
          TABLA.orden_trabajo,
          !!this.usuarioIdtecnico,
          !!this.usuarioIdcomercial,
          !!this.usuarioIdvigilante,
        )
        this.countOperacionCrm = resp.data.result.dataset.length
      }
    },
    clickIrAOperaciones () {
      this.$appRouter.push({
        name: 'operacionescrm__ordenes-operacioncrm-list',
        params: {
          idorden_trabajo: this.item.dataset.parte_trabajo.idorden_trabajo,
        },
      })
    }
  }
}
</script>