<template>
  <div
    class="px-4 mt-2"
    @click="click"
  >
    <v-sheet
      color="info"
      elevation="1"
      rounded
      width="100%"
      dark
    >
      <div
        class="subtitle-1 text-center py-1"
      >
        Ver {{ 'operaci'|pluralize(totales, 'ones', 'ón') }}
      </div>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    totales: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    click() {
      this.$emit('click-operaciones')
    },
  },
}
</script>
